import React from 'react';
import Img, { FixedObject } from 'gatsby-image';
import { useSelector } from 'react-redux';
import { graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import SEO from 'src/components/SEO';
import WorkoutCard from 'src/components/shared/WorkoutCard';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';

import {
    header,
    details,
    trainerImage,
    trainerDetailsWrapper,
} from 'src/styles/instructor-details.module.scss';
import { CardGridWrapper, ResponsivePadding, FloatingTryForFree } from 'src/components/shared';
import { useLocation, WindowLocation } from '@reach/router';
import { ContentfulInstructor_Details } from 'src/utils/graphql';
import Selectors from 'src/state/root-selectors';

// import * as Styles from 'src/styles/instructor-details.module.scss';

interface LocationState {
    from?: string;
}

interface InstructorDetailsPageTemplateProps {
    data: {
        contentfulInstructor: ContentfulInstructor_Details;
        placeholderImage: {
            childImageSharp: {
                fixed: FixedObject;
            };
        };
    };
}

const InstructorDetailsPageTemplate = ({ data }: InstructorDetailsPageTemplateProps) => {
    const location = useLocation() as WindowLocation<LocationState>;
    const {
        contentfulInstructor: {
            name: instructorName,
            bio,
            image: { fixed: instructorImage },
            workout: instructorWorkouts,
            active,
        },
        placeholderImage,
    } = data;

    if (!active) {
        navigate('/workouts');
    }

    const instructorBio = bio ? bio.bio : 'Bio Coming Soon';
    const instructorNameWithoutPeriod = instructorName.toString().replace(/\.$/, '');

    const imageProps = {
        alt: `${instructorNameWithoutPeriod}'s trainer image`,
        className: `circle-image ${trainerImage}`,
    };

    const numWorkouts = instructorWorkouts?.length || 0;
    const entitled = useSelector(Selectors.auth.entitled);
    const from = location?.state?.from?.toString().toLowerCase() || '';
    const backSuffix = from.includes('series')
        ? 'Back to series details'
        : from.includes('workouts')
            ? 'Back to workout details'
            : 'Back';

    const TrainerDetailsDesktop = (
        <Grid container>
            <Grid.Row
                className={classNames('padding--0', {
                    [`${details}`]: true,
                })}
            >
                <Grid.Column width={10} className="padding--0 ">
                    <h1 className={classNames('bold', 'upper')}>{instructorName}</h1>
                    <p className="margin-right--large">{instructorBio}</p>
                </Grid.Column>
                <Grid.Column width={6} textAlign="right" className="padding--0">
                    <Img
                        fixed={
                            instructorImage
                                ? instructorImage
                                : placeholderImage.childImageSharp.fixed
                        }
                        {...imageProps}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    const TrainerDetailsMobile = (
        <ResponsivePadding>
            <Grid container className="margin--0">
                <Grid.Row
                    verticalAlign="middle"
                    columns={2}
                    className={classNames('padding--0', {
                        [`${details}`]: true,
                    })}
                >
                    <Grid.Column className="padding--0">
                        <h1 className="bold upper margin--0">{instructorName}</h1>
                    </Grid.Column>

                    <Grid.Column textAlign="right" className="padding--0">
                        <Img
                            fixed={
                                instructorImage
                                    ? instructorImage
                                    : placeholderImage.childImageSharp.fixed
                            }
                            {...imageProps}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <p>{instructorBio}</p>
                </Grid.Row>
            </Grid>
        </ResponsivePadding>
    );

    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);

    /**
     * In this template we can access Contentful data one of two ways
     *  - Use the slug to make SDK calls to get data in the client at runtime
     *  - OR, use a gatsby page query to directly query Contenful during build time
     */
    return (
        active && (
            <div className={trainerDetailsWrapper}>
                <SEO title={`Instructor: ${instructorName}`} />
                <Grid>
                    <Grid.Row className={`${header} page-section`} verticalAlign="middle">
                        <Grid.Column>
                            {isMobile ? TrainerDetailsMobile : TrainerDetailsDesktop}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="page-section">
                        <ResponsivePadding>
                            <Grid container>
                                <Grid.Row columns={isMobile ? 1 : 2} verticalAlign="bottom">
                                    <Grid.Column className="padding--0" floated="left">
                                        <h2 className="bold upper">
                                            {instructorNameWithoutPeriod}'s Workouts
                                        </h2>
                                    </Grid.Column>
                                    {numWorkouts && (
                                        <Grid.Column
                                            className="padding--0"
                                            floated="right"
                                            textAlign={isMobile ? 'left' : 'right'}
                                        >
                                            <span>
                                                {numWorkouts} Workout
                                                {numWorkouts > 1 ? 's' : ''}
                                            </span>
                                        </Grid.Column>
                                    )}
                                </Grid.Row>
                                <CardGridWrapper>
                                    {numWorkouts ? (
                                        instructorWorkouts.map((workout, index: number) => {
                                            return (
                                                <WorkoutCard
                                                    key={`workout-card-${index}`}
                                                    workout={workout}
                                                />
                                            );
                                        })
                                    ) : (
                                        <p>No workouts found</p>
                                    )}
                                </CardGridWrapper>
                            </Grid>
                        </ResponsivePadding>
                    </Grid.Row>
                </Grid>
                {!entitled && <FloatingTryForFree />}
            </div>
        )
    );
};

export const query = graphql`
    query InstructorDetailsPageQuery($contentful_id: String!) {
        contentfulInstructor(contentful_id: { eq: $contentful_id }) {
            ...ContentfulInstructor_Details
        }
        placeholderImage: file(relativePath: { eq: "placeholders/placeholder-trainer.png" }) {
            childImageSharp {
                fixed(width: 398, quality: 80) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;

export default InstructorDetailsPageTemplate;
